<template>
  <div>
    <div class="header">
      <h4>招聘职位列表</h4>
      <div style="display: flex; align-item: center">
        <el-button
          v-access
          data-ctrl="recruit.add"
          @click="addObject"
          size="small"
          type="primary"
          icon="el-icon-plus"
          >新建
        </el-button>
      </div>
    </div>
    <ListFilter :total="total" @close="putAway">
      关键字：
      <el-input
        placeholder="请输入关键字"
        v-model="formInline.keywords"
        @input="loadData()"
        size="mini"
        style="margin-right: 10px; width: 300px"
        prefix-icon="el-icon-search"
        clearable
      >
      </el-input>
    </ListFilter>
    <el-table :data="list" style="width: 100%">
      <el-table-column
        type="index"
        label="序号"
        width="50"
        fixed
      ></el-table-column>
      <el-table-column label="职位名称" fixed width="180">
        <template slot-scope="scope">
          <span class="clickColor" @click="goDetail(scope.row.code)">{{
            scope.row.name
          }}</span>
        </template>
      </el-table-column>

      <el-table-column label="招聘企业" width="180">
        <template slot-scope="scope">
          {{ scope.row.enterpriseName }}
        </template>
      </el-table-column>

      <el-table-column label="招聘人数" width="100">
        <template slot-scope="scope"> {{ scope.row.number }} </template>
      </el-table-column>

      <el-table-column label="工作地点" width="300" show-overflow-tooltip="">
        <template slot-scope="scope">
          {{ scope.row.location }},{{ scope.row.locationDetail }}
        </template>
      </el-table-column>
      <el-table-column label="返佣计划" width="300" show-overflow-tooltip="">
        <template slot-scope="scope">
          <el-button
            type="text"
            v-prev
            :path="'/system/recruit/returnDetail'"
            :query="{ name: scope.row.returnPlan }"
          >
            {{ scope.row.returnPlan }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="学历" width="100">
        <template slot-scope="scope">
          {{ scope.row.educational }}
        </template>
      </el-table-column>

      <el-table-column label="专业" width="100">
        <template slot-scope="scope">
          {{ scope.row.major }}
        </template>
      </el-table-column>

      <el-table-column label="工作年限" width="100">
        <template slot-scope="scope">
          {{ scope.row.minWorkingYears }}
        </template>
      </el-table-column>

      <el-table-column label="薪资范围" width="180">
        <template slot-scope="scope">
          {{ scope.row.salaryMinAmount }} ~
          {{ scope.row.salaryMaxAmount }}/人·{{ scope.row.salaryUnit }}
        </template>
      </el-table-column>

      <el-table-column label="联系人" width="150">
        <template slot-scope="scope">
          {{ scope.row.contacts }}
        </template>
      </el-table-column>

      <el-table-column label="联系方式" width="150">
        <template slot-scope="scope">
          {{ scope.row.tel }}
        </template>
      </el-table-column>

      <el-table-column label="保证金" width="150">
        <template slot-scope="scope">
          {{ scope.row.deposit }}
        </template>
      </el-table-column>

      <el-table-column label="有效期" width="150">
        <template slot-scope="scope">
          {{ scope.row.validityTime | dateVal }}
        </template>
      </el-table-column>

      <!-- <el-table-column label="福利 eg:五险一金,带薪年假">
        <template slot-scope="scope">
          {{ scope.row.welfare }}
        </template>
      </el-table-column> -->

      <!-- <el-table-column label="分享海报图片">
        <template slot-scope="scope">
          {{ scope.row.postImage }}
        </template>
      </el-table-column> -->

      <!-- <el-table-column label="招聘内容页">
        <template slot-scope="scope">
          {{ scope.row.content }}
        </template>
      </el-table-column> -->

      <!-- <el-table-column label="创建时间">
        <template slot-scope="scope">
          {{ scope.row.createTime }}
        </template>
      </el-table-column> -->

      <!-- <el-table-column label="是否在banner中显示">
        <template slot-scope="scope">
          {{ scope.row.isTop }}
        </template>
      </el-table-column> -->

      <!-- <el-table-column label="置顶优先级">
        <template slot-scope="scope">
          {{ scope.row.topIndex }}
        </template>
      </el-table-column>

      <el-table-column label="是否在banner中显示">
        <template slot-scope="scope">
          {{ scope.row.isBanner }}
        </template>
      </el-table-column> -->

      <!-- <el-table-column label="banner图片">
        <template slot-scope="scope">
          {{ scope.row.bannerImage }}
        </template>
      </el-table-column> -->

      <!-- <el-table-column label="备注">
        <template slot-scope="scope">
          {{ scope.row.remark }}
        </template>
      </el-table-column> -->

      <el-table-column label="关联合同" width="200">
        <template slot-scope="scope">
          <div>{{ scope.row.linkContractName }}</div>
          <div>{{ scope.row.linkContractCode }}</div>
        </template>
      </el-table-column>

      <!-- <el-table-column label="状态 1草稿 2已发布 3已关闭">
        <template slot-scope="scope">
          {{ scope.row.state }}
        </template>
      </el-table-column> -->

      <el-table-column label="操作" fixed="right" width="180">
        <template slot-scope="scope">
          <span
            class="clickColor"
            @click="goEdit(scope.row.code)"
            v-access
            data-ctrl="recruit.edit"
            >编辑</span
          >
          <el-divider direction="vertical"></el-divider>
          <el-button
            slot="reference"
            @click="publish"
            type="text"
            v-access
            data-ctrl="recruit.publish"
            >发布</el-button
          >
          <el-divider direction="vertical"></el-divider>
          <!-- <div style="display: inline-block"> -->
          <el-dropdown>
            <el-button type="text"
              >更多<i class="el-icon-arrow-down el-icon--right"></i
            ></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                @click="publish"
                v-access
                data-ctrl="recruit.publish"
                >发布</el-dropdown-item
              >
              <el-dropdown-item v-access data-ctrl="recruit.stop"
                >终止</el-dropdown-item
              >
              <el-dropdown-item
                style="color: #f56c6c"
                v-access
                data-ctrl="recruit.del"
                >删除</el-dropdown-item
              >
              <el-dropdown-item divided></el-dropdown-item>
              <el-dropdown-item
                v-access
                data-ctrl="recruit.viewCard"
                v-prev
                :path="'/system/recruit/recruitView'"
                :query="{ code: scope.row.code }"
                >查看浏览记录
              </el-dropdown-item>
              <el-dropdown-item
                v-access
                data-ctrl="recruit.enlistCard"
                v-prev
                :path="'/system/recruit/recruitEnlist'"
                :query="{ code: scope.row.code }"
                >报名人员
              </el-dropdown-item>

              <el-dropdown-item
                v-access
                data-ctrl="recruit.returnCard"
                v-prev
                :path="'/system/recruit/payList'"
                :query="{ code: scope.row.code }"
                >返佣名单
              </el-dropdown-item>

              <el-dropdown-item divided></el-dropdown-item>
              <el-dropdown-item
                v-prev
                :path="'/system/content/editContent'"
                :query="{ code: scope.row.content, type: 'recruit' }"
                >编辑宣传页</el-dropdown-item
              >
              <el-dropdown-item
                v-prev
                :path="'/system/content/Detail'"
                :query="{ code: scope.row.content, type: 'recruit' }"
                >查看宣传页
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- </div> -->
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 10px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNumber"
        :page-size="pageSize"
        layout="prev,pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <!--    
      路由配置 
    {
      path: 'recruit/list',
      name: 'RecruitList',
      component: () => import(/* webpackChunkName: "recruit_list" */ '../views/recruit/List.vue')
    }, {
      path: 'recruit/detail',
      name: 'RecruitDetail',
      component: () => import(/* webpackChunkName: "recruit_detail" */ '../views/recruit/Detail.vue')
    },{
      path: 'recruit/add',
      name: 'RecruitAdd',
      component: () => import(/* webpackChunkName: "recruit_add" */ '../views/recruit/Add.vue')
    },{
      path: 'recruit/edit',
      name: 'RecruitEdit',
      component: () => import(/* webpackChunkName: "recruit_edit" */ '../views/recruit/Edit.vue')
    }
  //接口：
  import { api } from "/src/api/base";
  //新增接口 
  export const RecruitAdd= api("/recruit")('/recruit/recruit.add.json')
  //查询列表接口
  export const RecruitList= api("/recruit")('/recruit/recruit.list.json')
  //查询详情接口
  export const RecruitDetail= api("/recruit")('/recruit/recruit.describe.json')
  //编辑接口
  export const RecruitEdit= api("/recruit")('/recruit/recruit.edit.json')
  //删除接口
  export const RecruitDel= api("/contractTemplate")('/contractTemplate/contractTemplate.del.json')
  
  //文件上传接口
  export const RecruitUploadPath='/recruit/recruit.upload.json'
  
  --></div>
</template>

<script>
//接口：
import { api } from '/src/api/base';
//新增接口
export const RecruitAdd = api('/recruit')('recruit.add.json');
//查询列表接口
export const RecruitList = api('/recruit')('recruit.list.json');
//查询详情接口
export const RecruitDetail = api('/recruit')('recruit.describe.json');
//编辑接口
export const RecruitEdit = api('/recruit')('recruit.edit.json');
//删除接口
export const RecruitDel = api('/recruit')('recruit.del.json');
export default {
  components: {
    ListFilter: () => import('/src/components/filter')
  },
  data() {
    return {
      pageSize: 20,
      pageNumber: 1,
      total: 0,
      formInline: {
        keywords: ''
      },
      list: []
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.loadData();
    },
    loadData() {
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        ...this.formInline
      };
      RecruitList(params).then(res => {
        this.list = res.list;
        this.total = res.total;
      });
    },
    onSubmit() {
      console.log('submit!');
    },
    addObject() {
      this.$router.push({
        path: '/system/recruit/add'
      });
    },
    goEdit(obj) {
      this.$router.push({
        path: '/system/recruit/add',
        query: {
          code: obj
        }
      });
    },
    goDetail(obj) {
      this.$router.push({
        path: '/system/recruit/detail',
        query: {
          code: obj
        }
      });
    },
    del(obj) {
      RecruitDel(obj).then(() => {
        this.$message.success('内容成功删除！');
        this.loadData();
      });
    },
    putAway() {
      this.formInline.keywords = '';
      this.pageNumber = 1;
      this.loadData();
    },
    viewRecruitRule() {
      window.open('/content/kokwt1xo0/document.html?skin=document');
    },
    publish() {
      this.$confirm('确定您以对招聘内容进行审核，并核对保证金?', '提示', {
        confirmButtonText: '发布',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '发布成功!'
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消发布'
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  border-bottom: 1px solid #ebeef5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clickColor {
  color: #409eff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
